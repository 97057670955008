import React, { useState } from 'react'
import { Col, Container, Row, Breadcrumb } from 'react-bootstrap'
import { graphql } from 'gatsby'
import propTypes from 'prop-types'

import axios from 'axios'
import Layout from '../../layouts/default'
import Banner from '../../components/sections/_banner/default'
import Heading from '../../components/app-components/dashed-heading'
import ContactForm from '../../components/app-components/contact-form'
import ContactLocations from '../../components/app-components/contact-location'
import ContactFormConfirmation from '../../components/app-components/contact-form-confirmation'
import './styles.scss'

const ContactTemplate = ({
  data: {
    wpPage: { template:{acfContact:acf}, seo },
  },
}) => {
  const [submited, submitState] = useState(false)
  const [customerEmail, setCustomerEmail] = useState('')

  const submitHandler = async data => {
    try {
      const result = await axios.post(
        `${process.env.GATSBY_BOOKER_URL}/contact`,
        data,
        {
          headers: { 'content-type': 'application/json' },
        }
      )

      if (result.data.success === 'undefined' || result.data.success !== 1) {
        return false
      }
      if (data.email) {
        setCustomerEmail(data.email)
      }
      window.scrollTo(0, 0)
      submitState(!submited)
      return true
    } catch (error) {
      return false
    }
  }

  return (
    <Layout seo={seo}>
      <Banner
        title="Contactez nous"
        backgroundImg={acf.headerImageFile?.sourceUrl}
        bannerClass="contact-banner"
      />

      <Container className="p-0">
        <Breadcrumb class="contact-breadcrumb">
          <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
          <Breadcrumb.Item active>Contactez Nous</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Container className="contact-page py-5">
        {submited && <ContactFormConfirmation customerEmail={customerEmail} />}
        {!submited && (
          <Row className="justify-content-center">
            <Col xs="12" md="8" lg="8" className="top-section text-center mt-4">
              <Heading
                className="heading"
                title={acf.headerTitle}
                description={acf.headerDescription}
              />
              {acf?.headerAdresses && (
                <ContactLocations adresses={acf.headerAdresses} />
              )}
            </Col>
          </Row>
        )}
        {!submited && (
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <ContactForm submit={e => submitHandler(e)} />
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  )
}

ContactTemplate.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}

export const query = graphql`
  query ContactPage($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      title
      template{
        ... on WpTemplate_Contact{
          acfContact {
            headerTitle
            headerDescription
            headerImageFile {
              sourceUrl
            }
            headerAdresses{
              title
              street
              postalCode
              city
              transports
            }
          }
        }
      }
      id
      ...yoastMeta
    }
  }
`

export default ContactTemplate
