import React, { useState } from 'react'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import propTypes from 'prop-types'
import * as yup from 'yup'
import useForm from 'react-hook-form'
import CustomSelect from '../custom-select'
import ErrorMessage from '../form-error-message'
import { CONTACT_TOPICS } from '../../../constants/options'
import { trackGTM } from '../../../helpers'

const ContactForm = ({ submit }) => {
  const [form, setForm] = useState({
    company: '',
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    subject: '',
    message: '',
  })
  const { company, firstName, lastName, email, tel, subject, message } = form

  const formSchema = yup.object().shape({
    company: yup.string(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup
      .string()
      .required()
      .email()
      .max(100),
    tel: yup
      .string()
      .required()
      .matches(/0[0-9]{9}/),
    subject: yup.string().required(),
    message: yup.string().required(),
  })

  const {
    register,
    errors,
    setError,
    setValue,
    handleSubmit: wrapSubmit,
  } = useForm({
    validationSchema: formSchema,
    mode: 'onChange',
  })

  const handleChange = ({ target: { value, name } }) => {
    // validate form on input change
    setValue(name, value, true)
    setForm({ ...form, [name]: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const res = await submit(form)
    if (res !== true) {
      setError([
        { name: 'firstName' },
        { name: 'lastName' },
        { name: 'email' },
        { name: 'tel' },
        { name: 'subject' },
        { name: 'message' },
      ])
    } else {
      trackGTM({
        event: 'contactForm',
        object: 'quote',
      })
    }
  }

  return (
    <Container fluid className="contact-form">
      <Row className="justify-content-center">
        <Col md={12} sm={12}>
          <Form onSubmit={e => wrapSubmit(handleSubmit(e))}>
            <h3 className="mt-lg-5">
              Besoin d&apos;informations ? Contactez nous
            </h3>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group"
                controlId="company"
              >
                <Form.Control
                  placeholder="Nom de l'entreprise"
                  name="company"
                  ref={register}
                  defaultValue={company}
                  onChange={handleChange}
                  isInvalid={typeof errors.company !== 'undefined'}
                />
                <Form.Label>Nom de l&apos;entreprise</Form.Label>
                <ErrorMessage name="company" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group firstname-wrapper"
                controlId="firstName"
              >
                <Form.Control
                  placeholder="Prénom*"
                  name="firstName"
                  ref={register}
                  defaultValue={firstName}
                  onChange={handleChange}
                  isInvalid={typeof errors.firstName !== 'undefined'}
                />
                <Form.Label>Prénom*</Form.Label>
                <ErrorMessage name="firstName" errors={errors} />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group lastname-wrapper"
                controlId="lastName"
              >
                <Form.Control
                  placeholder="Nom*"
                  name="lastName"
                  ref={register}
                  defaultValue={lastName}
                  onChange={handleChange}
                  isInvalid={typeof errors.lastName !== 'undefined'}
                />
                <Form.Label>Nom*</Form.Label>
                <ErrorMessage name="lastName" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group"
                controlId="email"
              >
                <Form.Control
                  placeholder="Email"
                  name="email"
                  ref={register}
                  defaultValue={email}
                  onChange={handleChange}
                  isInvalid={typeof errors.email !== 'undefined'}
                />
                <Form.Label>Email*</Form.Label>
                <ErrorMessage name="email" errors={errors} />
              </Form.Group>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group"
                controlId="tel"
              >
                <Form.Control
                  placeholder="Numéro de téléphone"
                  name="tel"
                  ref={register}
                  defaultValue={tel}
                  onChange={handleChange}
                  isInvalid={typeof errors.phone !== 'undefined'}
                />
                <Form.Label>Numéro de téléphone*</Form.Label>
                <ErrorMessage name="tel" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group
                as={Col}
                xs={12}
                lg
                className="form-label-group select-wrapper"
                controlId="subject"
              >
                <CustomSelect
                  name="subject"
                  register={register}
                  defaultValue={subject}
                  setValue={() => true}
                  options={CONTACT_TOPICS}
                  placeholder="Objet de la prise de contact"
                  selected={handleChange}
                  additionalClass="w-100"
                  isInvalid={typeof errors.subject !== 'undefined'}
                />
                <ErrorMessage name="subject" errors={errors} />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="message">
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Votre message*"
                name="message"
                ref={register}
                defaultValue={message}
                onChange={handleChange}
                isInvalid={typeof errors.message !== 'undefined'}
              />
              <ErrorMessage name="message" errors={errors} />
            </Form.Group>
            <div className="text-center mt-5">
              <Button size="lg" variant="warning" type="submit">
                ENVOYER
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

ContactForm.propTypes = {
  submit: propTypes.func.isRequired,
}

export default ContactForm
