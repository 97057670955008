import React, { useEffect, useState } from 'react'
import { Dropdown, Form } from 'react-bootstrap'
import propTypes from 'prop-types'
import Icon from '../icon'
import './styles.scss'

const CustomSelect = ({
  options,
  placeholder,
  selected,
  name,
  register,
  setValue,
  defaultValue,
  additionalClass,
}) => {
  const val = options.find(o => o.value === defaultValue)?.label || defaultValue

  const [selectedValue, selectVal] = useState(val)

  useEffect(() => {
    selectVal(val)
  }, [val])

  const handleChange = option => {
    // classic form case
    if (setValue !== null) {
      setValue(name, option.value)
      selected({ target: { value: option.value, name } })
    } else {
      selected(option.value)
    }
  }

  // set props for FormControl
  const controlProps = {
    type: 'text',
    placeholder: ' ',
    readOnly: true,
    value: selectedValue,
    name,
  }
  if (register !== null) {
    controlProps.ref = register
  }
  return (
    <div
      className={`custom-select-component ${additionalClass || ''} ${
        selectedValue !== '' ? 'selected' : ''
      }`}
    >
      <Form.Control {...controlProps} />
      <Form.Label className="custom-select-label">{placeholder}</Form.Label>
      <Dropdown className="custom-select-wrapper">
        <Dropdown.Toggle className="custom-select-trigger" />
        <Icon name="icon-arrow-down" />
        <Dropdown.Menu className="custom-select-dropdown" flip={false}>
          {options.map(option => (
            <Dropdown.Item
              key={option.value}
              active={selectedValue === option.label}
              className="custom-select-option"
              href="#"
              data-value={option.value}
              onClick={() => selectVal(option.label)}
              onSelect={() => handleChange(option)}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default CustomSelect

CustomSelect.propTypes = {
  options: propTypes.objectOf(propTypes.any).isRequired,
  placeholder: propTypes.string.isRequired,
  selected: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  register: propTypes.func,
  setValue: propTypes.func,
  defaultValue: propTypes.string.isRequired,
  additionalClass: propTypes.string,
}
CustomSelect.defaultProps = {
  register: null,
  setValue: null,
  additionalClass: null,
}
