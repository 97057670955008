import React from 'react'
import { Row, Col } from 'react-bootstrap'
import propTypes from 'prop-types'
import Heading from '../dashed-heading'
import Button from '../button'

const ContactFormConfirmation = ({ customerEmail }) => {
  return (
    <Row className="justify-content-center">
      <Col xs="12" md="8" lg="8" className="top-section text-center mt-4">
        <Heading
          className="heading"
          title="Confirmation de la demande"
          description={`Votre demande a bien été envoyée à Booké.<br> Vous recevrez une réponse sous 48h à l'adresse suivante <strong>${customerEmail}.</strong>`}
        />

        <div className="text-center mt-5">
          <Button
            name="Retour à l'accueil"
            size="lg"
            variant="warning"
            to="/"
          />
        </div>
      </Col>
    </Row>
  )
}
ContactFormConfirmation.propTypes = {
  customerEmail: propTypes.string.isRequired,
}
export default ContactFormConfirmation
