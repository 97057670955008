/* eslint-disable  react/prop-types, camelcase */
import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Icon from '../icon'

const ContactLocations = ({ adresses }) => (
  <Row className="text-center">
    {adresses &&
      adresses.map(
        ({ title, street, postalCode, city, transports }) => (
          <Col lg="6" md="6" className="mt-lg-5">
            <div className="contact-location-item">
              <Icon name="icon-location" paths={6} />
              <div>
                <strong>{title}</strong>
              </div>
              <div>{street}</div>
              <div>
                {postalCode} {city}
              </div>
              <div>
                <strong>{transports}</strong>
              </div>
            </div>
          </Col>
        )
      )}
  </Row>
)

export default ContactLocations
